<template>
	<section class="complete-eval">
		<div class="complete-eval__container">
			<div class="head">
				<h1 class="head--title">{{ $t("CompleteEvaluation.Title") }}</h1>
				<p class="head--question">{{ $t("CompleteEvaluation.Question") }}</p>
			</div>

			<img v-lazy="logo" alt class="logo w-75" />

			<div class="action">
				<CButton v-bind="{ variant: 'outline' }" color="primary" @click="appointmentForTesting">{{
					$t("CompleteEvaluation.Appointment")
				}}</CButton>
				<CButton color="primary" @click="getFreeStrips">{{
					$t("CompleteEvaluation.GetFree")
				}}</CButton>
				<CButton v-bind="{ variant: 'ghost' }" color="primary" @click="backHomePage">{{
					$t("CompleteEvaluation.BackHome")
				}}</CButton>
			</div>
		</div>
	</section>
</template>

<script>
import logo from "@/assets/img/oral_quick.svg"

export default {
	name: "CompleteEvaluation",

	props: {
		isMobile: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			logo,
		}
	},

	computed: {},

	watch: {},

	async created() {
		await window.parent.postMessage("finish-risk-assessment", "*")
	},

	methods: {
		async appointmentForTesting() {
			const message = this.isMobile ? "exam-appointment-mobile" : "exam-appointment-xom"
			await window.parent.postMessage(message, "*")
		},

		async getFreeStrips() {
			const message = this.isMobile ? "product-booking-mobile" : "product-booking-xom"
			await window.parent.postMessage(message, "*")
		},

		async backHomePage() {
			const message = this.isMobile ? "back-home-page-mobile" : "back-home-page-xom"
			await window.parent.postMessage(message, "*")
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.complete-eval {
	@include form;
	@include font-lato;

	&__container {
		width: 100%;
		background: $color-white;
		border: 1px solid $color-white;
		border-radius: 12px;
		padding: 24px 14px;
		text-align: center;

		@media (min-width: $xs) {
			padding: 44px 40px 24px 40px;
		}

		h1 {
			font-size: 32px;
			background: $linear-gradient-pink;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.action {
		margin-top: 28px;

		.btn {
			font-size: 16px;
			padding: 14px 32px;
			width: 100%;
			font-weight: bold;
		}
		.btn + .btn {
			margin-top: 7px;
		}
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.head {
	&--title {
		font-weight: bold;
	}
	&--question {
		color: $color-black-pearl;
		font-weight: 400;
	}
}
</style>
